import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../store";
import { setNotionHide } from "../../../actions/notion";


// Данный компонент взаимодействует с редаксом. 
// При появлении уведомления меняется стейт и компонент возбуждается, если присутствует на странице

function NotionModal() {

  const dispatch = useDispatch();

  const { notionContent, notionView } = useSelector(
    (state: IStore) => state.notion
  );

  function handleClose() {
    dispatch(setNotionHide());
  }

  return (
    <>
      {notionView && (
        <div className="modal">
          <div className="modal-content">
            <h2>Уведомление</h2>
            <p>{notionContent}</p>
            <div className="modal-actions">
              <div className="d-flex mt-25">
                <button onClick={handleClose} className="success">
                  Понятно
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NotionModal;

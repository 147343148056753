import { configureStore } from "@reduxjs/toolkit";

import user from "../reducers/user";
import dashboard from "../reducers/dashboard";
import data from "../reducers/data";
import chart from "../reducers/chart";
import widget from "../reducers/widget";
import social from "../reducers/social";
import notion from "../reducers/notion";

export interface IStore {
  user: any;
  dashboard: any;
  data: any;
  chart: any;
  widget: any;
  social: any;
  notion: any;
}

const store = configureStore({
  reducer: { user, dashboard, data, chart, widget, social, notion },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

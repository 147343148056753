export const setLogin = (obj: string) => {
  return {
    type: "SET_LOGIN",
    payload: obj,
  };
};

export const setPassword = (obj: string) => {
  return {
    type: "SET_PASSWORD",
    payload: obj,
  };
};

export const setToken = (obj: string) => {
  return {
    type: "SET_TOKEN",
    payload: obj,
  };
};

export const setClear = () => {
  return {
    type: "SET_CLEAR",
  };
};

type TStringNull = string | null;

interface IState {
  login: TStringNull;
  password: string;
  token: TStringNull;
}


const initialState: IState = {
  login: localStorage.getItem("login"),
  password: "",
  token: localStorage.getItem("token"),
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_LOGIN":
      return {
        ...state,
        login: action.payload,
      };

    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };

    case "SET_CLEAR":
      return {
        ...state,
        token: null,
        login: "",
        password: "",
      };

    default:
      return state;
  }
};

export default user;

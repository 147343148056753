import styles from './header.module.css';

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { IStore } from "../../../../store";

import useLogout from "../../../../hooks/useLogout/useLogout";
import { Logo } from '../../../shared/Logo';
import { FlexContainer } from '../../../shared/FlexContainer';

export const Header = () => {

    const { login, token } = useSelector((state: IStore) => state.user);

    useEffect(() => { }, [login]);

    let localLogin = null;
    if (localStorage.getItem("login")) {
        localLogin = localStorage.getItem("login");
    }

    const exitFunc = useLogout(token);

    const renderLinks = (localLogin: any) => {
        if (localLogin) {
            if (typeof localLogin === "string") {
                return (
                    <>
                        <Link to="/account/dashboards" className={styles.link}>
                            Аккаунт
                        </Link>
                        <Link to="/documentation" className={styles.link}>Документация</Link>
                        <Link to="/login" className={styles.link}>{localLogin.substring(0, 15)}</Link>
                        <Link to="/login" onClick={exitFunc} className={styles.link}>
                            Выйти
                        </Link>
                    </>
                );
            }
        }
        return (
            <>
                <Link to="/documentation">Документация</Link>
                <Link to="/login">Войти</Link>
                <Link to="/login/register">Зарегистрироваться</Link>
            </>
        );
    };

    const links = renderLinks(localLogin);

    return (
        <div className={styles.header}>
            <div className={styles.content}>
                <FlexContainer justify="spaceBetween">
                    <FlexContainer>
                        <Logo />
                        <span>Быстрая и простая система дашбордов</span>
                    </FlexContainer>


                    <FlexContainer justify="flexEnd" gap="L">{links}</FlexContainer>
                </FlexContainer>
            </div>
        </div>
    );
}
interface IState {
  dataList: {} | null;
  title: string | null;
  description: string;
  data: string;
  time: string;
}

const initialState: IState = {
  dataList: null,
  title: "",
  description: "",
  data: "",
  time: "",
};

const data = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DATA_LIST":
      return {
        ...state,
        dataList: action.payload,
      };
    case "SET_DATA_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_DATA_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_DATA_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_DATA_TIME":
      return {
        ...state,
        time: action.payload,
      };
    case "SET_RESET_DATA":
      return {
        ...state,
        dataList: null,
        title: "",
        description: "",
        data: "",
        time: "",
      };
    default:
      return state;
  }
};

export default data;

import { useDispatch } from "react-redux";
import { setClear } from "../../actions/user";
import { intervalsLogout } from "../../api/oauth";

const useLogout = (token: string) => {

    const dispatch = useDispatch();
    const exitFunc = () => {
        intervalsLogout(token);
        localStorage.clear();
        dispatch(setClear());
    };

    return exitFunc;
};

export default useLogout;

import React from 'react';
import styles from './flexContainer.module.css';
import classNames from 'classnames';
import { baseSizeType } from '../../../../types/baseTypes';

export interface IFlexContainer {
    children: React.ReactNode;
    justify?: 'center' | 'flexStart' | 'flexEnd' | 'spaceBetween';
    gap?: baseSizeType
}

export const FlexContainer = ({ children, justify = 'flexStart', gap = 'M' }: IFlexContainer) => {
    const containerClasses = classNames(styles.container, {
        [styles[justify]]: justify,
        [styles[`gap${gap}`]]: gap,
    });

    return (
        <div className={containerClasses}>
            {children}
        </div>
    );
};

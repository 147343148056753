interface IState {
  socialList: [] | null;
}

const initialState: IState = {
  socialList: null,
};

const social = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SOCIAL_LIST":
      return {
        ...state,
        socialList: action.payload,
      };
    default:
      return state;
  }
};

export default social;

export const setNotionView = (obj: {}) => {
  return {
    type: "SET_NOTION_VIEW",
    payload: obj,
  };
};


export const setNotionHide = () => {
  return {
    type: "SET_NOTION_HIDE",
  };
};

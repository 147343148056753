import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer__links">
          <a href="https://ustsl.ru">DEV: ustsl.ru</a>
          <a href="https://t.me/ustsl">Помощь в телеграм</a>
          <a href="/privacy.pdf" target="_blank" rel="noopener noreferrer">
            Политика
          </a>
          <Link to="/documentation">Документация</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;

interface IState {
  dashboardUser: string | null;
  dashboardList: {} | null;
  title: string;
  description: string;
  charts: {};
  widgets: {};
}

const initialState: IState = {
  dashboardUser: null,
  dashboardList: null,
  title: "",
  description: "",
  charts: {},
  widgets: {},
};

const dashboard = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DASHBOARD_LIST":
      return {
        ...state,
        dashboardList: action.payload,
      };
    case "SET_DASHBOARD_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_DASHBOARD_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_DASHBOARD_CHARTS":
      return {
        ...state,
        charts: action.payload,
      };
    case "SET_DASHBOARD_WIDGETS":
      return {
        ...state,
        widgets: action.payload,
      };
    case "SET_DASHBOARD_USER":
      return {
        ...state,
        dashboardUser: action.payload,
      };
    case "SET_RESET_DASHBOARD":
      return {
        ...state,
        dashboardUser: null,
        dashboardList: null,
        title: "",
        description: "",
        charts: {},
        widgets: {},
      };
    default:
      return state;
  }
};

export default dashboard;

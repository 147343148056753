import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Spinner from "../shared/spinner/spinner";
import { Header } from "../widgets/Header";
import Footer from "../shared/footer/Footer";
import NotionModal from "../widgets/NotionModal/NotionModal";

const Login = lazy(() => import("../pages/oauth/Login"));
const ChangePass = lazy(() => import("../pages/oauth/ChangePass"));
const ForgotPass = lazy(() => import("../pages/oauth/ForgotPass"));
const ForgotPassCofirm = lazy(() => import("../pages/oauth/ForgotPassConfirm"));
const Register = lazy(() => import("../pages/oauth/Register"));
const Landing = lazy(() => import("../pages/landing/Landing"));
const Documentation = lazy(() => import("../pages/documentation/Documentation"));
const Dashboards = lazy(() => import("../pages/account/dashboard/Dashboards"));
const DashboardView = lazy(
  () => import("../pages/account/dashboard/DashboardView")
);
const Data = lazy(() => import("../pages/account/data/Data"));
const DataView = lazy(() => import("../pages/account/data/DataView"));
const Charts = lazy(() => import("../pages/account/chart/Charts"));
const ChartView = lazy(() => import("../pages/account/chart/ChartView"));
const Widgets = lazy(() => import("../pages/account/widget/Widgets"));
const WidgetView = lazy(() => import("../pages/account/widget/WidgetView"));
const Social = lazy(() => import("../pages/account/social/Social"));

const NotFound = lazy(() => import("../pages/notfound/NotFound"));

function App() {

  const wrapper_func = (elem: JSX.Element) => {
    return <Suspense fallback={<Spinner />}>{elem}</Suspense>;
  };

  return (

    <Router>
      <Header />
      <Routes>
        <Route path="/" element={wrapper_func(<Landing />)} />
        <Route path="/documentation" element={wrapper_func(<Documentation />)} />
        <Route path="/login" element={wrapper_func(<Login />)} />
        <Route path="/login/change" element={wrapper_func(<ChangePass />)} />
        <Route path="/login/forgot" element={wrapper_func(<ForgotPass />)} />
        <Route path="/login/register" element={wrapper_func(<Register />)} />
        <Route
          path="/account/dashboards"
          element={wrapper_func(<Dashboards />)}
        />
        <Route
          path="/account/dashboard/create"
          element={wrapper_func(<Dashboards />)}
        />
        <Route
          path="/account/dashboard/:id"
          element={wrapper_func(<DashboardView />)}
        />
        <Route path="/account/data" element={wrapper_func(<Data />)} />
        <Route
          path="/account/data/:id"
          element={wrapper_func(<DataView />)}
        />
        <Route path="/account/charts" element={wrapper_func(<Charts />)} />
        <Route
          path="/account/chart/:id"
          element={wrapper_func(<ChartView />)}
        />
        <Route path="/account/widgets" element={wrapper_func(<Widgets />)} />
        <Route
          path="/account/widget/:id"
          element={wrapper_func(<WidgetView />)}
        />
        <Route path="/account/social" element={wrapper_func(<Social />)} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={wrapper_func(<ForgotPassCofirm />)}
        />
        <Route path="*" element={wrapper_func(<NotFound />)} />
      </Routes>
      <NotionModal /><Footer />
    </Router>

  );
}

export default App;





import { Link } from 'react-router-dom';
import logo from './logo.svg';
import styles from './logo.module.css';

export const Logo = () => {
    return (
        <Link to="/" className={styles.logo}>
            <img src={logo} alt="logo intervals" className={styles.image} width={50} height={50} />
            INTERVALS
        </Link>
    )
}
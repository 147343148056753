interface IState {
    notionContent: string | null;
    notionView: boolean;
}

const initialState: IState = {
    notionContent: null,
    notionView: false
};

const notion = (state = initialState, action: any) => {
    switch (action.type) {

        case "SET_NOTION_VIEW":
            return {
                ...state,
                notionContent: action.payload,
                notionView: true
            };

        case "SET_NOTION_HIDE":
            return {
                ...state,
                notionContent: null,
                notionView: false
            };

        default:
            return state;
    }
};

export default notion;

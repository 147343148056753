interface IState {
  widgetList: {} | null;
  title: string;
  settings: {};
  data: {};
  number: number;
  dashboard: number | null;
}

const initialState: IState = {
  widgetList: null,
  title: "",
  settings: {},
  data: {},
  number: 0,
  dashboard: null,
};

const chart = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_WIDGET_LIST":
      return {
        ...state,
        widgetList: action.payload,
      };
    case "SET_WIDGET_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_WIDGET_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };
    case "SET_WIDGET_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_WIDGET_DASHBOARD":
      return {
        ...state,
        dashboard: action.payload,
      };
    case "SET_WIDGET_NUMBER":
      return {
        ...state,
        number: action.payload,
      };
    case "SET_RESET_WIDGET":
      return {
        ...state,
        widgetList: null,
        title: "",
        settings: {},
        data: {},
        number: 0,
        dashboard: null,
      };
    default:
      return state;
  }
};

export default chart;

import axios from "axios";
import { APILINK } from "./settings";

export async function intervalsRegister(login: string, password: string) {
  const url = APILINK + "account/api_register/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  const body = {
    email: login,
    password: password,
    is_active: true,
  };
  try {
    const response = await axios.post(url, body, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function intervalsLogin(login: string, password: string) {
  const url = APILINK + "auth/token/login/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  const body = {
    email: login,
    password: password,
  };
  try {
    const response = await axios.post(url, body, { headers });
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function intervalsChangePass(
  token: string,
  new_password: string,
  current_password: string
) {
  const url = APILINK + "authusers/set_password/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Token ${token}`,
  };
  const body = {
    new_password: new_password,
    current_password: current_password,
  };
  try {
    const response = await axios.post(url, body, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function intervalsLogout(token: string) {
  const url = APILINK + "auth/token/logout/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Token ${token}`,
  };
  try {
    const response = await axios.post(url, {}, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function intervalsForgotPass(email: string) {
  const url = APILINK + "authusers/reset_password/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  const body = {
    email: email,
  };
  try {
    const response = await axios.post(url, body, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function intervalsForgotPassConfirm(
  uid: string,
  token: string,
  new_password: string
) {
  const url = APILINK + "authusers/reset_password_confirm/";

  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  const body = {
    uid: uid,
    token: token,
    new_password: new_password,
  };
  try {
    const response = await axios.post(url, body, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
}

interface IState {
  chartList: {} | null;
  title: string;
  description: string;
  settings: {};
  data: {};
  dashboard: number | null;
  number: number;
}

const initialState: IState = {
  chartList: null,
  title: "",
  description: "",
  settings: {},
  data: {},
  dashboard: null,
  number: 0,
};

const chart = (state = initialState, action: any) => {
  switch (action.type) {

    case "SET_CHART_LIST":
      return {
        ...state,
        chartList: action.payload,
      };

    case "SET_CHART_TITLE":
      return {
        ...state,
        title: action.payload,
      };

    case "SET_CHART_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };

    case "SET_CHART_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };

    case "SET_CHART_DATA":
      return {
        ...state,
        data: action.payload,
      };

    case "SET_CHART_DASHBOARD":
      return {
        ...state,
        dashboard: action.payload,
      };

    case "SET_CHART_NUMBER":
      return {
        ...state,
        number: action.payload,
      };

    case "SET_RESET_CHART":
      return {
        ...state,
        chartList: null,
        title: "",
        description: "",
        settings: {},
        data: {},
        dashboard: null,
        number: 0,
      };

    default:
      return state;
  }
};

export default chart;
